import React, { useContext, useEffect, useState, useMemo } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubHeaderRight } from '../../../../layout/SubHeader/SubHeader';
import Page from '../../../../layout/Page/Page';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import { demoPages } from '../../../../menu';
import Card, { CardBody } from '../../../../components/bootstrap/Card';
import Button from '../../../../components/bootstrap/Button';
import TableCustom from '../../../../components/MyCustom/TableCustom';
import { ACCESS_LEVEL } from '../../../../types/roles';
import dateFormat from 'dateformat';
import { DefaultContext } from '../../../../contexts/default';
import Dropdown from '../../../../components/bootstrap/Dropdown';
import { DropdownToggle, DropdownMenu } from '../../../../components/bootstrap/Dropdown';
import useDarkMode from '../../../../hooks/useDarkMode';
import Label from '../../../../components/bootstrap/forms/Label';
import Select from '../../../../components/bootstrap/forms/Select';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import { useFormik } from 'formik';
import StoreDB from '../../../../database/wrappers/store';
import { orderBy } from 'firebase/firestore';
import useTotalByDay from '../../../../hooks/useTotalByDay';
import Money from '../../../../helpers/utils/money/money';
import Faturamento from './faturamento';
import Spinner from '../../../../components/bootstrap/Spinner';
import SalesDB from '../../../../database/wrappers/sales';
import useDashData from '../../../../hooks/useDashData';
import OrdersIfoodDB from '../../../../database/wrappers/orderIfood';
import OrdersRappiDB from '../../../../database/wrappers/orderRappi';
import { STATUS_ORDER } from '../../../../types/statusOrderDelivery';

const years = [
  {
    value: '2023',
    text: '2023',
  },
  {
    value: '2024',
    text: '2024',
  },
  {
    value: '2025',
    text: '2025',
  },
]

const months = [
  {
    value: '01',
    text: 'Janeiro',
  },
  {
    value: '02',
    text: 'Fevereiro',
  },
  {
    value: '03',
    text: 'Março',
  },
  {
    value: '04',
    text: 'Abril',
  },
  {
    value: '05',
    text: 'Maio',
  },
  {
    value: '06',
    text: 'Junho',
  },
  {
    value: '07',
    text: 'Julho',
  },
  {
    value: '08',
    text: 'Agosto',
  },
  {
    value: '09',
    text: 'Setembro',
  },
  {
    value: '10',
    text: 'Outubro',
  },
  {
    value: '11',
    text: 'Novembro',
  },
  {
    value: '12',
    text: 'Dezembro',
  },
];



const FaturamentoPorDia = () => {
  const { themeStatus } = useDarkMode()
  const [dataFilter, setdataFilter] = useState({
    estab: '',
    store: '',
    firstDate: '',
    secondDate: '',
  });
  const { accessLevel, establishments, estabSelected } = useContext(DefaultContext);
  // const { ordersIfood, ordersRappi } = useContext(ResultsContext)
  const [storesInFilter, setstoresInFilter] = useState([]);
  const [filterMenu, setFilterMenu] = useState(false);

  const salesdb = useMemo(() => new SalesDB(dataFilter.estab || 'x'), [dataFilter.estab]);
  const ifooddb = useMemo(() => new OrdersIfoodDB(dataFilter.estab || 'x'), [dataFilter.estab]);
  const rappidb = useMemo(() => new OrdersRappiDB(dataFilter.estab || 'x'), [dataFilter.estab]);

  const { rows: sales, loading: loadingSales } = useDashData(filterMenu, dataFilter, salesdb);
  const { rows: salesIfood, loading: loadingSalesIfood } = useDashData(filterMenu, dataFilter, ifooddb);
  const { rows: salesRappi, loading: loadingSalesRappi } = useDashData(filterMenu, dataFilter, rappidb);
  
  const { mergedByDay, isLoading, setIsLoading } = useTotalByDay([
    ...sales, 
    ...salesIfood.filter((order) => order.status === STATUS_ORDER.CONCLUIDO), 
    ...salesRappi.filter((order) => order.status === STATUS_ORDER.CONCLUIDO), 
  ])

  // const { mergedByDay, isLoading, setIsLoading } = useTotalByDay([
  //   ...sales.map(order => ({ ...order, source: 'normal' })),
  //   ...salesIfood.filter(order => order.status === STATUS_ORDER.CONCLUIDO).map(order => ({ ...order, source: 'ifood' })),
  //   ...salesRappi.filter(order => order.status === STATUS_ORDER.CONCLUIDO).map(order => ({ ...order, source: 'rappi' }))
  // ]);
  

  function getDaysInMonth(year, month) {
    return new Date(year, month, 0).getDate();
  }

  const formik = useFormik({
    initialValues: {
      estab: '',
      store: '',
      month: '',
      year: '',
    },
    onSubmit: (values) => {
      const daysInMonth = getDaysInMonth(parseInt(values.year), parseInt(values.month));
      setFilterMenu(false);
      setdataFilter({
        estab: values.estab,
        store: values.store,
        firstDate: `${values.year}-${values.month}-01 00:00:00`,
        secondDate: `${values.year}-${values.month}-${daysInMonth} 23:59:59`,
      });
      setIsLoading(true);
    },
  });


  useEffect(() => {
    formik.setFieldValue('estab', estabSelected)
  }, [])
  useEffect(() => {
    if (formik.values.estab) {
      const loadStores = () => {
        new StoreDB(formik.values.estab)
          .getAll(orderBy('name', 'asc'))
          .then(datas => {
            setstoresInFilter(datas)
            formik.setFieldValue('#')
          })
          .catch(error => console.error(error))
          .finally(() => {
          });
      }
      loadStores();

    }
  }, [formik.values.estab])

  const optionsEstablishments = useMemo(() =>
    establishments.map(item => ({ value: item.id, text: item.name }))
    , [establishments])


  const optionsStores = useMemo(() => {
    const options = storesInFilter.map(item => ({ value: item.id, text: item.name }))
    return options;
  }, [storesInFilter])


  const columns = useMemo(() => ([
    {
      label: 'Data',
      field: 'created_at',
      format: row => dateFormat(row.created_at, 'dd/mm/yyyy'),
      formatExport: value => dateFormat(value, 'dd/mm/yyyy'),

    },
    {
      label: 'Faturamento',
      field: 'total',
      format: row => Money.centsToMaskMoney(row.total),
      formatExport: value => Money.centsToCoinCsv(value)
    },
    {
      label: 'Faturamento Ifood',
      field: 'totalIfood',
      format: row => Money.centsToMaskMoney(row.totalIfood),
      formatExport: value => Money.centsToCoinCsv(value)
    },
    {
      label: 'Faturamento Rappi',
      field: 'totalRappi',
      format: row => Money.centsToMaskMoney(row.totalRappi),
      formatExport: value => Money.centsToCoinCsv(value)
    },
    {
      label: 'Qtd. Atend',
      field: '{row}',
      format: row => (row.count),
      formatExport: row => (row.count)
    },
    {
      label: 'Qtd. Atend Ifood',
      field: '{row}',
      format: row => (row.countIfood ),
      formatExport: row => (row.countIfood)
    },
    {
      label: 'Qtd. Atend Rappi',
      field: '{row}',
      format: row => (row.countRappi ),
      formatExport: row => (row.countRappi )
    },
    {
      label: 'Ticket Médio',
      field: '{row}',
      format: row => Money.centsToMaskMoney(row.total  / row.count || 0),
      formatExport: row => Money.centsToCoinCsv(Math.round(row.total  / row.count || 0)),
    },
    {
      label: 'Ticket Médio iFood',
      field: '{row}',
      format: row => Money.centsToMaskMoney(row.totalIfood / row.countIfood || 0),
      formatExport: row => Money.centsToCoinCsv(Math.round( row.totalIfood  / row.countIfood || 0)),
    },
    {
      label: 'Ticket Médio Rappi',
      field: '{row}',
      format: row => Money.centsToMaskMoney(row.totalRappi / row.countRappi || 0),
      formatExport: row => Money.centsToCoinCsv(Math.round( row.totalRappi / row.countRappi || 0)),
    },
  ]), [])



  return (
    <>
      <PageWrapper title={demoPages.reports.subMenu.faturamentoPorDia.text}>
        <SubHeader>
          <SubHeaderLeft>
            <Breadcrumb
              list={[
                { title: 'Relatórios', to: '/reports/faturamentoPorDia' },
                {
                  title: 'Faturamento',
                  to: '/reports/faturamentoPorDia',
                },
              ]}
            />
          </SubHeaderLeft>
          <SubHeaderRight>
            <Dropdown isOpen={filterMenu} setIsOpen={setFilterMenu}>
              <DropdownToggle hasIcon={false}>
                <Button
                  style={filterMenu ? { color: themeStatus === 'dark' ? 'black' : 'white' } : {}}
                  className='text-dark'
                  icon='Filter' color='warning' data-tour='filter'>
                  Filtros
                </Button>
              </DropdownToggle>

              <DropdownMenu
                isAlignmentEnd
                size='lg'
                isCloseAfterLeave={false}
                data-tour='filter-menu'>
                <div className='container py-2'>
                  <form className='row g-3' onSubmit={formik.handleSubmit}>
                    {(accessLevel === ACCESS_LEVEL.ADMIN || accessLevel === ACCESS_LEVEL.MANAGER) &&
                      <div className='col-12'>
                        <FormGroup>
                          <Label htmlFor='unity'>Estabelecimento</Label>
                          <Select
                            id='estab'
                            placeholder='Selecione o estabelecimento'
                            list={optionsEstablishments}
                            onChange={formik.handleChange}
                            value={formik.values.estab}
                          />
                        </FormGroup>
                      </div>}
                    <div className='col-12'>
                      <FormGroup>
                        <Label htmlFor='store'>Loja</Label>
                        <Select
                          id='store'
                          placeholder='Selecione a loja'
                          list={optionsStores}
                          onChange={formik.handleChange}
                          value={formik.values.store}
                        />
                      </FormGroup>
                    </div>
                    <div className='col-12'>
                      <FormGroup>
                        <Label htmlFor='unity'>Mês</Label>
                        <Select
                          id='month'
                          placeholder='Selecione o mês'
                          list={months}
                          onChange={formik.handleChange}
                          value={formik.values.month}
                        />
                      </FormGroup>
                    </div>

                    <div className='col-12'>
                      <FormGroup>
                        <Label htmlFor='unity'>Ano</Label>
                        <Select
                          id='year'
                          placeholder='Selecione o ano'
                          list={years}
                          onChange={formik.handleChange}
                          value={formik.values.year}
                        />
                      </FormGroup>
                    </div>
                    <div className='col-6'>
                      <Button
                        className='w-100 text-dark bg-white'
                        style={themeStatus === 'dark' ? { color: 'white' } : {}}
                        color='warning'
                        isOutline
                        onClick={formik.resetForm}>
                        Resetar
                      </Button>
                    </div>
                    <div className='col-6'>
                      <Button style={{ color: 'black' }} color='warning' className='w-100' type='submit'>
                        Filtrar
                      </Button>
                    </div>
                  </form>
                </div>
              </DropdownMenu>
            </Dropdown>
          </SubHeaderRight>
        </SubHeader>
        <Page container='fluid'>

          {isLoading && loadingSales && loadingSalesIfood && loadingSalesRappi ? (
            <div className='d-flex justify-content-center my-auto'>
              <Spinner
                className='text-center'
                color='blue'
                size={72}
                tag='span'
              />
            </div>

          ) : (
            <>
              <Faturamento mergedByDay={mergedByDay} />
              <Card>
                <CardBody className='table-responsive p-5'>
                  <TableCustom
                    rows={mergedByDay}
                    columns={columns}
                    keyExtractor={item => item.uid}
                  />
                </CardBody>
              </Card>
            </>
          )}
        </Page>
      </PageWrapper >
    </>
  );
};

export default FaturamentoPorDia;
