import React, { useCallback, useMemo, useContext, useEffect, useState } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft } from '../../../../layout/SubHeader/SubHeader';
import Page from '../../../../layout/Page/Page';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import { demoPages } from '../../../../menu';
import { DefaultContext } from '../../../../contexts/default';
import Card, { CardBody } from '../../../../components/bootstrap/Card';
import Button from '../../../../components/bootstrap/Button';
import TableCustom from '../../../../components/MyCustom/TableCustom';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import SelectStore from '../../../../components/MyCustom/SelectStore';
import useDarkMode from '../../../../hooks/useDarkMode';
import StockComplementDB from '../../../../database/wrappers/stockComplements';
import ComplementDB from '../../../../database/wrappers/complement';
import ModalRegisterStockComplements from '../../../../components/modals/StockComplements/ModalRegister'
import { orderBy } from 'firebase/firestore';
import logo from '../../../../assets/img/logo_uget_black.png'
import { ResultsContext } from '../../../../contexts/results';

const StockComponents = () => {
  const { darkModeStatus } = useDarkMode();

  const { establishment, store, onShowAlert, settings } = useContext(DefaultContext)

  const [complements, setcomplements] = useState(null)
  const [stockcomplementsDicionary, setstockcomplementsDicionary] = useState(null)
  const [complementsRender, setcomplementsRender] = useState([])
  const [complementselected, setcomplementselected] = useState(null);
  const [showModal, setshowModal] = useState(false);

  useEffect(() => {
    if (!establishment) return;
    const onSubscribe = new ComplementDB(establishment.id).on(setcomplements, orderBy('name', 'asc'));
    return onSubscribe
  }, [establishment])

  useEffect(() => {
    if (!establishment || !store) return;
    const onSubscribe = new StockComplementDB(establishment.id, store.id).on(datas => {
      const dicionary = {};
      datas.forEach(data => dicionary[data.id] = data)
      setstockcomplementsDicionary(dicionary)
    });
    return onSubscribe
  }, [establishment, store])

  useEffect(() => {
    if (complements !== null && stockcomplementsDicionary !== null) {
      const complementsRender = complements.map(product => {
        const stock = stockcomplementsDicionary[product.id];
        return {
          ...product,
          ...stock,
          active: !stock ? true : stock.active,
          stockExist: !!stock,
        }
      })

      setcomplementsRender(complementsRender);
    }
  }, [complements, stockcomplementsDicionary])

  const handleOpenModalEdit = useCallback((item) => {
    setcomplementselected(item)
    setshowModal(true)
  }, [])

  const handleChangeStatus = useCallback((id, item) => {
    const onSuccess = () => { };
    const onError = () => {
      onShowAlert({
        title: 'ERRO!',
        msm: 'Não foi possível atualizar o status do produto',
        icon: 'Error',
        color: 'danger'
      })
    };
    if (item.stockExist) {
      new StockComplementDB(establishment.id, store.id)
        .update(item.id, {
          active: !item.active
        })
        .then(onSuccess)
        .catch(onError)
    } else {
      new StockComplementDB(establishment.id, store.id)
        .define(item.id, {
          active: !item.active
        })
        .then(onSuccess)
        .catch(onError)
    }
  }, [establishment, store])

  const columns = useMemo(() => ([
    {
      label: 'Imagem',
      field: 'image_url',
      format: row => (
        <>
          {row.image_url ? (
            <img src={row.image_url} alt="Imagem" width={54} height={54} />
          ) : (
            <img src={logo} alt="Imagem" width={60} height='auto' />
          )}
        </>
      ),
      noExport: true
    },
    {
      label: 'Nome',
      field: 'name',
    },
    {
      label: 'Nome Sec.',
      field: 'secondName',
      hidden: !settings?.ecommerce

    },
    {
      label: 'Sku',
      field: 'sku',
      hidden: !settings?.ecommerce

    },
    {
      label: 'Quantidade',
      field: 'qtd',
    },
    // {
    //   label: 'Status',
    //   field: 'status',
    //   format: row => (
    //     <>
    //       <Button
    //         isLink
    //         color={row.active ? 'success' : 'danger'}
    //         icon='Circle'
    //         onClick={() => handleChangeStatus(row.id, row)}
    //         className='text-nowrap'>
    //         {row.active ? 'Ativo' : 'Inativo'}
    //       </Button>
    //     </>
    //   )
    // },
    {
      label: 'Ações',
      field: 'actions',
      format: row => (
        <div className='w-50 d-flex justify-content-between'>
          <Button
            color={darkModeStatus ? 'light' : 'dark'}
            icon="edit"
            hoverShadow="md"
            size="sm"
            text=''
            onClick={() => handleOpenModalEdit(row)}
          />
        </div>
      ),
      noExport: true
    },
  ]), [handleChangeStatus, handleOpenModalEdit])

  return (
    <>
      <PageWrapper title={demoPages.pdvLoja.subMenu.stockComponents.text}>
        <SubHeader>
          <SubHeaderLeft>
            <Breadcrumb
              list={[
                { title: 'PDV Loja', to: '/pdvLoja/stockComponents' },
                { title: 'Estoque Complementos', to: '/pdvLoja/stockComponents' },
              ]}
            />
          </SubHeaderLeft>
        </SubHeader>
        <Page container='fluid'>
          <Card>
            <CardBody className='table-responsive p-5'>
              <div className='row d-flex align-items-center mt-4 mx-1' >
                <SelectEstablishment />
                <SelectStore />
              </div>
              <TableCustom columns={columns} rows={complementsRender} fileName={'Estoque-complemente'} />
            </CardBody>
          </Card>
        </Page>
      </PageWrapper>

      <ModalRegisterStockComplements
        open={showModal}
        setIsOpen={setshowModal}
        setIsClose={() => setshowModal(false)}
        stockSelected={complementselected}
      />
    </>
  );
};

export default StockComponents;
