export const processSaleProducts = (saleProducts: any[], productsDicionary: any, complamentsDicionary: any) => {
    return saleProducts.flatMap((product: any) => {
      const baseProduct = product.qtd !== 0 ? createProductObject(product, 0, product.id, 0, productsDicionary, complamentsDicionary) : null;
      const complementProducts = (product.complements || []).map((complement: any) => {
        return complement.qtd !== 0 ? createProductObject(complement, 1, complement.id, 0, productsDicionary, complamentsDicionary) : null;
      }).filter(Boolean);
  
      return baseProduct !== null ? [baseProduct, ...complementProducts] : complementProducts;
    });
  };
  
  export const processCommandProducts = (commands: any[], productsDicionary: any, complamentsDicionary: any) => {
    return commands.flatMap((command: any) => {
      const products = Object.entries(command.products || {}).map(([id, product]: [string, any]) => {
        if (product.qtd !== 0) {
          return createProductObject(product, 0, id, 1, productsDicionary, complamentsDicionary);
        } else {
          return null;
        }
      }).filter(Boolean);
  
      const complements = Object.entries(command.complements || {}).map(([id, complement]: [string, any]) => {
        if (complement.qtd !== 0) {
          return createProductObject(complement, 1, id, 1, productsDicionary, complamentsDicionary);
        } else {
          return null;
        }
      }).filter(Boolean);
  
      return [...products, ...complements];
    });
  };
  
  export const createProductObject = (item: any, type: any, id: any, typeSale: any, productsDicionary: any, complamentsDicionary: any) => {
    const productId = id;
    const productName = getTypeDictionary(type, productsDicionary, complamentsDicionary)[productId]?.name || '';
    return {
      id: productId,
      name: productName,
      price: typeSale === 0 ? item.price - (item.complements_total || 0) : Math.round(item.total / item.qtd),
      amount: item.qtd,
       totalFiscal: typeSale === 0 ? Math.round(item.qtd * (item.price - (item.complements_total || 0))) : item.total,
      qtdFiscal: item.qtd,
      type,
    };
  };
  


  export const processProductsDelivery = (deliveryProducts: any[]) => {
    const productsDelivery: any[] = [];
    deliveryProducts?.forEach((product) => {
      productsDelivery.push({
        id: product.id,
        name: product.name,
        price: product.unitPrice,
        amount: product.qtd,
        totalFiscal: product.qtd * product.unitPrice,
        qtdFiscal: product.qtd,
        type: 0,
      })

      product?.complements?.forEach((complement: any) => {
        productsDelivery.push({
          id: complement.id,
          name: complement.name,
          price: complement.unitPrice,
          amount: complement.qtd,
          totalFiscal: complement.qtd * complement.unitPrice,
          qtdFiscal: complement.qtd,
          type: 1
        })
      })
    })
    
    return productsDelivery;
  };
  
  export const getTypeDictionary = (type: number, productsDicionary: any, complamentsDicionary: any) => {
    return type === 0 ? productsDicionary : complamentsDicionary;
  };