import { orderBy } from "firebase/firestore";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import Breadcrumb from "../../../../components/bootstrap/Breadcrumb";
import Button from "../../../../components/bootstrap/Button";
import Card, { CardBody } from "../../../../components/bootstrap/Card";
import SelectEstablishment from "../../../../components/MyCustom/SelectEstablishment";
import SelectStore from "../../../../components/MyCustom/SelectStore";
import TableCustom from "../../../../components/MyCustom/TableCustom";
import { DefaultContext } from "../../../../contexts/default";
import CategoryComplementDB from "../../../../database/wrappers/categoryComplement";
import ComplementCategoryStoreDB from "../../../../database/wrappers/complementsCategoryStore";
import UserHistory from "../../../../database/wrappers/userHistory";
import Page from "../../../../layout/Page/Page";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import SubHeader, { SubHeaderLeft } from "../../../../layout/SubHeader/SubHeader";
import { demoPages } from "../../../../menu";


const CategoriaComplemento = () => {
  const { establishment, store, onShowAlert, user } = useContext(DefaultContext)
  const [category, setCategory] = useState(null)

  const [categoryComplementDicionary, setCategoryComplementDicionary] = useState([])
  const [categoryRender, setCategoryRender] = useState([]);


  useEffect(() => {
    if (!establishment || !store) return;
    const onSubscribe = new CategoryComplementDB(establishment.id).on(setCategory, orderBy('name', 'asc'));
    return onSubscribe
  }, [establishment, store])

  useEffect(() => {
    if (!establishment || !store) return;
    const onSubscribe = new ComplementCategoryStoreDB(establishment.id, store.id).on(datas => {
      const dicionary = {};
      datas.forEach(data => dicionary[data.id] = data)
      setCategoryComplementDicionary(dicionary)
    });
    return onSubscribe;
  }, [establishment, store])


  useEffect(() => {
    if (category !== null && categoryComplementDicionary !== null) {
      const categoryRender = category.map(product => {
        const storeProd = categoryComplementDicionary[product.id];
        return {
          ...product,
          ...storeProd,
          active: !storeProd ? true : storeProd.active,
          storeProdExist: !!storeProd,
        }
      })

      setCategoryRender(categoryRender);
    }
  }, [category, categoryComplementDicionary])

  const handleChangeStatus = useCallback((item) => {
    const onSuccess = () => { };
    const onError = () => {
      onShowAlert({
        title: 'ERRO!',
        msm: 'Não foi possível atualizar o status do produto',
        icon: 'Error',
        color: 'danger'
      })
    };

    if (item.storeProdExist) {
      new ComplementCategoryStoreDB(establishment.id, store.id)
        .update(item.id, {
          active: !item.active
        })
        .then(() => {
          onSuccess()
          new UserHistory(establishment.id, user, "a categoria de complemento", store.name)
            .update(item.id, { data: { active: !item.active }, selected: item })
            .finally(() => { })
        })
        .catch(onError)
    } else {
      new ComplementCategoryStoreDB(establishment.id, store.id)
        .define(item.id, {
          active: !item.active
        })
        .then(() => {
          onSuccess()
          new UserHistory(establishment.id, user, "a categoria de complemento", store.name)
            .update(item.id, { data: { active: !item.active }, selected: item })
            .finally(() => { })
        })
        .catch(onError)
    }
  }, [establishment, store])


  const columns = useMemo(() => ([
    {
      label: 'Nome',
      field: 'name',
    },
    {
      label: 'Descrição',
      field: 'description',
    },
    {
      label: 'Status',
      field: 'active',
      format: row => (
        <>
          <Button
            isLink
            color={row.active ? 'success' : 'danger'}
            icon='Circle'
            onClick={() => handleChangeStatus(row)}
            className='text-nowrap'>
            {row.active ? 'Ativo' : 'Inativo'}
          </Button>
        </>
      )
    },
  ]), [handleChangeStatus])

  return (
    <>
      <PageWrapper title={demoPages.pdvLoja.subMenu.complementCatergoria.text}>
        <SubHeader>
          <SubHeaderLeft>
            <Breadcrumb
              list={[
                { title: 'PDV Loja', to: '/pdvLoja' },
                { title: 'Complementos na Loja', to: 'pdvLoja/complementCatergoria' },
              ]}
            />
          </SubHeaderLeft>
        </SubHeader>
        <Page container='fluid'>
          <Card>
            <CardBody className='table-responsive p-5'>
              <div className='row d-flex align-items-center mt-4 mx-1' >
                <SelectEstablishment />
                <SelectStore />
              </div>
              <TableCustom columns={columns} rows={categoryRender} />
            </CardBody>
          </Card>
        </Page>
      </PageWrapper>


    </>
  );
}
export default CategoriaComplemento;