import { useFormik } from 'formik';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { DefaultContext } from '../../../../contexts/default';
import { ResultsContext } from '../../../../contexts/results';
import ComplementDB from '../../../../database/wrappers/complement';
import ProductDB from '../../../../database/wrappers/product';
import Money from '../../../../helpers/utils/money/money';
import PreAlert from '../../../../helpers/utils/preAlert';
import Button from '../../../bootstrap/Button';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../bootstrap/Modal';
import FormGroup from '../../../bootstrap/forms/FormGroup';
import Input from '../../../bootstrap/forms/Input';
import Spinner from '../../../bootstrap/Spinner';

const ModalEditProduct = ({ productSelected, open, setIsOpen, setIsClose, productsDicionary, complementsDicionary, estabId }) => {
  const { onShowAlert } = useContext(DefaultContext)
  const [isLoading, setIsLoading] = useState(false);

  const product = useMemo(() => {
    if (productSelected) {
      return (
        productSelected &&
        (productsDicionary && productsDicionary[productSelected.id]
          ? { ...productsDicionary[productSelected.id], isProduct: true, id: productSelected.id }
          : complementsDicionary && complementsDicionary[productSelected.id]
            ? { ...complementsDicionary[productSelected.id], isComplement: true }
            : null)
      );
    }
    return null;
  }, [productSelected, productsDicionary, complementsDicionary]);


  const onSuccessUpdate = () => {
    onShowAlert(PreAlert.success('Produto atualizado com sucesso.'))
    setIsClose();
  }
  const onError = () => {
    onShowAlert(PreAlert.error('Houve um erro ao atualizar o produto.'))
  }


  useEffect(() => {
    if (!open) return formik.resetForm();
    if (productSelected && product) {
      const { name, fiscal_data: { ncm, cfop, cest, csosn, cst } } = product;
      const { amount, price } = productSelected;
      formik.setValues({
        name: name || '',
        price: price || '',
        amount: amount || '',
        ncm: ncm || '',
        cfop: cfop || '',
        cest: cest || '',
        csosn: csosn || '',
        cst: cst || '',
      });
    }
  }, [productSelected, open, product]);



  const formik = useFormik({
    initialValues: {
      name: product ? product.name : '',
      price: product ? product.price : '',
      amount: product ? product.amount : '',
      ncm: product ? product.fiscal_data?.ncm : '',
      cest: product ? product.fiscal_data?.cest : '',
      cst: product ? product.fiscal_data?.cst : '',
      cfop: product ? product.fiscal_data?.cfop : '',
    },
    onSubmit: values => {
      setIsLoading(true);
      const fiscal_data = {
        ncm: values.ncm,
        cest: values.cest,
        cst: values.cst,
        cfop: values.cfop,
        csosn: values.csosn,
      };

      const data = {
        fiscal_data,
      }
      if (product) {
        const db = product.isProduct
          ? new ProductDB(estabId)
          : product.isComplement
            ? new ComplementDB(estabId)
            : null;

        if (db) {
          db
            .update(product.id, data)
            .then(onSuccessUpdate)
            .catch(onError)
            .finally(() => setIsLoading(false));
        }
      }
    }
  })
  return (
    <>
      <Modal
        id={'modal-request'}
        titleId={'request'}
        isOpen={open}
        setIsOpen={setIsOpen}
        isStaticBackdrop={true}
        isScrollable={false}
        isCentered={true}
        size="sm" // 'sm' || 'lg' || 'xl' 
        isAnimation={true}
      >
        <ModalHeader setIsOpen={setIsOpen}>
          <ModalTitle id="register-request"> Correção de produto</ModalTitle>
        </ModalHeader>
        <ModalBody className="p-4">
          <form onSubmit={formik.handleSubmit}>
            <div>
              <div className='d-flex flex-column align-items-center col-md-12'>
                <img src={product?.image_url || ''} alt="foto do produto" height={100} width={100} />
                <h3 style={{ marginTop: 20, marginBottom: 20 }}>{product?.name}</h3>
              </div>
              <div className='d-flex justify-content-between '>
                <FormGroup id='price' label='Preço (UN)' className='col-md-5' >
                  <Input
                    value={Money.centsToMaskMoney(productSelected?.price)}
                    disabled
                  />
                </FormGroup>
                <FormGroup id='amount' label='Quantidade' className='col-md-5'>
                  <Input
                    value={productSelected?.amount}
                    disabled
                  />
                </FormGroup>
              </div>

              <div className='d-flex justify-content-between '>
                <FormGroup id='ncm' label='NCM' className='col-md-5' >
                  <Input
                    name='ncm'
                    onChange={formik.handleChange}
                    value={formik.values.ncm}

                  />
                </FormGroup>
                <FormGroup id='cfop' label='CFOP' className='col-md-5'>
                  <Input
                    name="cfop"
                    onChange={formik.handleChange}
                    value={formik.values.cfop}
                  />
                </FormGroup>
              </div>
              <div className='d-flex justify-content-between '>
                <FormGroup id='cest' label='CEST' className='col-md-5' >
                  <Input
                    name="cest"
                    onChange={formik.handleChange}
                    value={formik.values.cest}
                  />
                </FormGroup>
                <FormGroup id='csosn' label='CSON' className='col-md-5'>
                  <Input
                    name="csosn"
                    onChange={formik.handleChange}
                    value={formik.values.csosn}
                  />
                </FormGroup>

              </div>
              <FormGroup id='cst' label='CST' className='col-md-5 '>
                <Input
                  name="cst"
                  onChange={formik.handleChange}
                  value={formik.values.cst}
                />
              </FormGroup>
              {/* Buttons */}
              <div className="d-flex justify-content-between  " style={{ marginTop: 20 }}>

                {isLoading ? (
                  <Button color='success' rounded={1} hoverShadow='sm' shadow='sm'>
                    <Spinner
                      color='light'
                      inButton
                      isGrow
                      isSmall
                      size={10}
                      tag='span'>
                      Carregando...
                    </Spinner>
                    Carregando...
                  </Button>
                ) : (

                  <Button
                    color="success"
                    icon="check"
                    rounded={1}
                    shadow="sm"
                    hoverShadow="sm"
                    size="sm"
                    type="submit"
                  >
                    Confirmar
                  </Button>
                )}



                < Button
                  color="danger"
                  icon="cancel"
                  rounded={1}
                  onClick={setIsClose}
                  shadow="sm"
                  hoverShadow="sm"
                  size="sm"
                >
                  Fechar
                </Button>

              </div>
            </div>

          </form>
        </ModalBody>
      </Modal >
    </>
  );
}
export default ModalEditProduct;