import Store from "../../database/entities/store.entity";
import apiFiscal from "../../services/apiFiscal";
import Money from "../../helpers/utils/money/money";
import { PAYMENT_TYPE, PAYMENT_TYPE_DELIVERY } from "../../types/payment";
import CommandProduct from "../../database/entities/sales/commandProduct";
import { OrderIfood } from "../../types/ordersIfood";
import { OrderRappi } from "../../types/orderRappi";
class FiscalDelivery {
  private sentData: any;
  constructor(
    private productsDicionary: any,
    private complementsDicionary: any,
  ) { }

  generateFiscal(products: CommandProduct[], store: Store, order:  OrderIfood | OrderRappi) {
    const productsFiscal = this.mountProductsFiscal(products);
    return this.postFiscal(productsFiscal, store, order)
  }

  private postFiscal(products: any[], store: Store, order: OrderIfood | OrderRappi) {
    const intermediateCNPJ = order.origin === 'RAPPI' ? "26900161000125" : "14380200000121"
    const intermediateName = order.origin === 'RAPPI' ? "Rappi" : "Ifood"
    const data = {
      total_price: parseFloat(products.reduce((prev, curr) => prev + curr.price * curr.amount, 0).toFixed(2)),
      cnpj: store.cnpj.replace(/\D/g, ""),
      payment_type: this.convertType(order?.payInfo[0]?.method),
      freight: order.deliveryFee,
      products,
      client: {
        cpf_cnpj: order.documentNumber,
        street: order.deliveryInfo.streetName || ' ',
        neighborhood: order.deliveryInfo.neighborhood || ' ',
        country: order.country || ' ',
        city: order.deliveryInfo.city || ' ',
        state_uf: order.deliveryInfo.state || ' ',
        email: order.email,
        code_country: order.code_country || ' ',
        code_city: order.code_city || `11`,
        zip_code: order.deliveryInfo.postalCode || ' ',
    },
    deliveryman: {
        city: order.city || ' ',
        uf_state: order.state || ' ',
    },
    intermediate: {
        cnpj: intermediateCNPJ,
        name: intermediateName,
    }
    }
    console.log(JSON.stringify(data))
    this.sentData = data;
    return apiFiscal.getIssuedFiscalDelivery(data)
  }
  private mountProductsFiscal(products: CommandProduct[]) {
    const productsFiscal = products.map(product => {
      const dictionary = (product as any).type === 0 ? this.productsDicionary : this.complementsDicionary;
      return {
        id: product.id,
        name: dictionary[product.id]?.name || 'Indefinido',
        price: Money.centsToCoin(Math.round(product.totalFiscal / product.qtdFiscal)),
        amount: product.qtdFiscal,
        // Dados fiscais
        ncm: dictionary[product.id]?.fiscal_data?.ncm,
        cfop: dictionary[product.id]?.fiscal_data?.cfop,
        cest: dictionary[product.id]?.fiscal_data?.cest,
        csosn: dictionary[product.id]?.fiscal_data?.csosn,
        cst: dictionary[product.id]?.fiscal_data?.cst,
        icms: dictionary[product.id]?.fiscal_data?.aliq_icms,
      }
    })
    return productsFiscal;
  }

  private distributeDiscount(products: any[], discount: number, payment_type: number) {
    let remainingDiscount = discount / 100;
    products.forEach(p => {
      const totalPrice = p.price * p.amount;
      const value = totalPrice > remainingDiscount ? remainingDiscount : totalPrice;
      p.discount = payment_type === PAYMENT_TYPE.COURTESY ? totalPrice : value;
      remainingDiscount -= value;
    })
  }

  private convertType(type: string) {
    if (type === PAYMENT_TYPE_DELIVERY.CASH) return '01';
    if (type === PAYMENT_TYPE_DELIVERY.CARD_CREDIT) return '03';
    if (type === PAYMENT_TYPE_DELIVERY.CARD_DEBIT) return '04';
    if (type === PAYMENT_TYPE_DELIVERY.PIX) return '20';
    return '01';
  }

  public getSentData() {
    return this.sentData;
  }

}

export default FiscalDelivery;