import FirestorePipe from "../utils/pipe";
import { QueryConstraint, Unsubscribe } from "firebase/firestore";
import EstablishmentDB from "./establishment";
import StoreDB from "./store";
import StoreSubCategory from "../entities/stores/storeSubCategory";

class StoreSubCategoryDB extends FirestorePipe {

    public static readonly colName = 'SubCategories'

    constructor(idEstab: string, idStore: string) {
        if (!idEstab)
            throw new Error('Informe o id do estabelecimento')
        super(`${EstablishmentDB.colName}/${idEstab}/${StoreDB.colName}/${idStore}/${StoreSubCategoryDB.colName}`);
    }

    public define(id: string, data: StoreSubCategory): Promise<any> {
        return this._define(id, data);
    }
    public create(data: StoreSubCategory): Promise<any> {
        return this._create(data);
    }
    public update(id: string, data: StoreSubCategory): Promise<any> {
        return this._update(id, data);
    }
    public delete(id: string): Promise<any> {
        return this._delete(id);
    }
    public getAll(...params: QueryConstraint[]): Promise<StoreSubCategory[]> {
        return this._getAll<StoreSubCategory>(...params);
    }
    public get(id: string): Promise<StoreSubCategory> {
        return this._get(id);
    }
    public on(listener: (data: StoreSubCategory[]) => void, ...params: QueryConstraint[]): Unsubscribe {
        return this._on(listener, ...params);
    }

}

export default StoreSubCategoryDB;