import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { DefaultContext } from "../../../contexts/default";
import PreAlert from "../../../helpers/utils/preAlert";
import SalesDB from "../../../database/wrappers/sales";
import masks, { unmask } from "../../../helpers/utils/masks";
import Fiscal from "../../../utils/fiscal";
import { ResultsContext } from "../../../contexts/results";
import LogFiscalReemissao from "../../../database/wrappers/logFiscalReemissao";
import Button from "../../bootstrap/Button";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../bootstrap/Modal";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Input from "../../bootstrap/forms/Input";
import Money from "../../../helpers/utils/money/money";
import TableCustom from "../../MyCustom/TableCustom";
import Textarea from "../../bootstrap/forms/Textarea";
import Spinner from "../../bootstrap/Spinner";
import { PAYMENT_TYPE_DELIVERY, PAYMENT_TYPE_DELIVERY_PTBR } from "../../../types/payment";
import ModalEditProduct from "../FixFiscalReports/editProduct";
import { processProductsDelivery } from "../../../utils/fiscal/fiscalFunctions";
import FiscalDelivery from "../../../utils/fiscal/fiscalDelivery";
import OrdersIfoodDB from "../../../database/wrappers/orderIfood";
import OrdersRappiDB from "../../../database/wrappers/orderRappi";
import StoreFiscalRappiDB from "../../../database/wrappers/storeFiscalRappi";
import StoreFiscalIfoodDB from "../../../database/wrappers/storeFiscalIfood";
import StoreDB from "../../../database/wrappers/store";
import ProductDB from "../../../database/wrappers/product";
import ComplementDB from "../../../database/wrappers/complement";

const ModalEditFiscal = ({ id, bodyData, open, setIsOpen, setIsClose, onClose,estabId, storeId }) => {
  const { onShowAlert, stores, user } = useContext(DefaultContext)
  const { boxOfficeDicionary } = useContext(ResultsContext);
  const [openProduct, setOpenProduct] = useState(false);
  const [productSelected, setProductSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [errorMsg, setErrorMsg] = useState(null);
  const [storesDicionary, setstoresDicionary] = useState({});
  const [productsDicionary, setProductsDicionary] = useState({});
  const [complementsDicionary, setComplementsDicionary] = useState({});
  const toggleModal = useCallback(() => setOpenProduct(state => !state), [])

  const onEditProduct = useCallback((productSelected) => {
    setProductSelected(productSelected);
    toggleModal();
  }, [toggleModal])

  
  const products = useMemo(() => {
    return processProductsDelivery(bodyData?.products);
  }, [bodyData?.products]);
  
  useEffect(() => {
    if (!estabId || !storeId) return;
    const loadStores = () => {
      new StoreDB(estabId)
        .getAll()
        .then(stores => {
          setstoresDicionary(stores)
        })
        .catch(error => console.error(error))
    }

    const loadProducts = () => {
      new ProductDB(estabId)
        .getAll()
        .then(products => {
          const productsDicionary = {};
          products.forEach(product => productsDicionary[product.id] = product)
          setProductsDicionary(productsDicionary)
        })
        .catch(error => console.error(error))
    }

    const loadComplements = () => {
      new ComplementDB(estabId)
        .getAll()
        .then(complements => {
          const complementsDicionary = {};
          complements.forEach(complement => complementsDicionary[complement.id] = complement)
          setComplementsDicionary(complementsDicionary)
        })
        .catch(error => console.error(error))
    }
    loadComplements();
    loadProducts();
    loadStores();
  }, [estabId, storeId, openProduct])
  

  const onSuccessUpdate = useCallback(() => {
    onShowAlert(PreAlert.success('Sua nota fiscal foi gerada com sucesso.'))
    setIsClose();
    onClose();
  }, [onShowAlert, setIsClose]);

  const onError = useCallback(() => {
    onShowAlert(PreAlert.error('Ocorreu um erro ao gerar nota fiscal, verifique os dados e tente novamente..'))
    setIsClose();
  }, [onShowAlert])

  useEffect(() => {
    if (!open) {
      setError(null);
      setErrorMsg(null);

    }
  }, [open])

  const handleGenerateFiscal = useCallback(async () => {
    setIsLoading(true);
    const firebaseOrderDb = bodyData.origin === 'RAPPI' ? OrdersRappiDB : OrdersIfoodDB
    const fiscalDb = bodyData.origin === 'RAPPI' ? StoreFiscalRappiDB : StoreFiscalIfoodDB

    const payment = await new firebaseOrderDb(estabId).get(bodyData?.id)
    if (payment && payment?.fiscal?.issued) {
      setIsLoading(false)
      return onShowAlert(PreAlert.error('Nota fiscal já emitida.'))
    }
    const products = processProductsDelivery(bodyData?.products);
    const storeSales = storesDicionary.find(item => item.id === bodyData?.storeId);
    const fiscalInstance = new FiscalDelivery(productsDicionary, complementsDicionary);
    const responseFiscal = await fiscalInstance.generateFiscal(products, storeSales,bodyData);
    let fiscal;
    if (responseFiscal.data) {
      fiscal = {
        file_url: responseFiscal?.data?.file_url || responseFiscal?.data?.note_url,
        id: responseFiscal?.data?.fiscal_id || responseFiscal?.data?.id,
        issued: responseFiscal?.data?.issued,
      };
    }
    const dataFiscal = {
      fiscal,
    };


    if (responseFiscal?.data?.issued && estabId) {
      new firebaseOrderDb(estabId)
        .update(id, dataFiscal)
        .then(onSuccessUpdate)
        .catch(onError)
        .finally(() => setIsLoading(false));

      new fiscalDb(estabId, bodyData?.storeId).delete(bodyData?.orderId)
      new LogFiscalReemissao(estabId, bodyData?.storeId, user.uid).send(fiscalInstance.getSentData(), fiscal, id);
    }

    if (responseFiscal.status === 400) {
      const logMsg = responseFiscal?.data.msg || responseFiscal?.data;
      setErrorMsg(logMsg);
      console.log(responseFiscal);
      const log = responseFiscal?.data?.Documentos?.[0]?.Situacao?.SitDescricao || 'Erro desconhecido';

      setError(log)
      setIsLoading(false);
    }

  }, [bodyData, boxOfficeDicionary, complementsDicionary, estabId, id, onSuccessUpdate, productsDicionary, stores, onError, user])


  const columns = useMemo(() => ([
    {
      label: 'Nome',
      field: 'name',
    },
    {
      label: 'Ação',
      field: 'action',
      format: row => {
        return <Button
          color="light"
          icon="edit"
          shadow="sm"
          hoverShadow="sm"
          size="sm"
          onClick={() => onEditProduct(row)}
        />

      }
    }
  ]), [onEditProduct])

  const handlechangeCpfCnpj = (e) => {
    if (e.target.value.length <= 18) {
      setCpfCnpj(e.target.value)
    }
  }

  return (
    <>
      <Modal
        id={'modal-request'}
        titleId={'request'}
        isOpen={open}
        setIsOpen={setIsOpen}
        isStaticBackdrop={true}
        isScrollable={false}
        isCentered={true}
        size="lg" // 'sm' || 'lg' || 'xl' 
        isAnimation={true}
      >
        <ModalHeader setIsOpen={setIsOpen}>
          <ModalTitle id="register-request"> Correção de nota fiscal</ModalTitle>
        </ModalHeader>
        <ModalBody className="p-4">
          <div>
            <div className="row g-4">
              <div className='d-flex gap-2  flex-column'>

                <FormGroup id='name' label='Nome' >
                  <Input
                    value={bodyData?.clientName || 'Não informado'}
                    disabled
                    placeholder='100'
                  />
                </FormGroup>

                <FormGroup id='cpf/cnpj' label='CPF/CNPJ' >
                  <Input
                    value={cpfCnpj.length <= 14 ? masks.cpf(cpfCnpj) : masks.cnpj(cpfCnpj)}
                    onChange={handlechangeCpfCnpj}

                  // disabled
                  />
                </FormGroup>
                <FormGroup className='d-flex gap-4  '>

                  <FormGroup id='subTotal' label='Total' >
                    <Input
                      value={Money.centsToMaskMoney(bodyData?.subTotal || 0)}
                      disabled
                    />
                  </FormGroup>

                  <FormGroup id='subTotal' label='Valor pago' >
                    <Input
                      value={Money.centsToMaskMoney(bodyData?.subTotal || 0)}
                      disabled
                    />
                  </FormGroup>

                  <FormGroup id='discount' label='Desconto' >
                    <Input
                      value={Money.centsToMaskMoney(bodyData?.discount || 0)}
                      disabled
                    />
                  </FormGroup>

                  <FormGroup id='payment_type' label='Tipo de Pagamento' >
                    <Input
                      value={PAYMENT_TYPE_DELIVERY_PTBR?.[bodyData?.payInfo?.[0]?.method] ?? PAYMENT_TYPE_DELIVERY_PTBR[PAYMENT_TYPE_DELIVERY.CASH]}
                      disabled
                    />
                  </FormGroup>
                </FormGroup>
              </div>
              <TableCustom
                title="Produtos"
                columns={columns}
                rows={products}
                keyExtractor={item => item.id}
                noShowHeader
              />
            </div>
            {(error || errorMsg) && (
              <Textarea
                value={error || errorMsg}
                disabled
                rows={5}
              />
            )}
            {/* Buttons */}
            <div className="d-flex flex-row-reverse gap-3" style={{ marginTop: 10 }}>
              <Button
                color="danger"
                icon="cancel"
                rounded={1}
                onClick={setIsClose}
                shadow="sm"
                hoverShadow="sm"
                size="sm"
              >
                Fechar
              </Button>
              {isLoading ? (
                <Button color='success' rounded={1} hoverShadow='sm' shadow='sm'>
                  <Spinner
                    color='light'
                    inButton
                    isGrow
                    isSmall
                    size={10}
                    tag='span'>
                    Carregando...
                  </Spinner>
                  Carregando...
                </Button>
              ) : (
                <Button
                  color="success"
                  icon="Send"
                  rounded={1}
                  onClick={handleGenerateFiscal}
                  shadow="sm"
                  hoverShadow="sm"
                  size="sm">
                  Emitir
                </Button>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>

      <ModalEditProduct
        productSelected={productSelected}
        open={openProduct}
        setIsClose={toggleModal}
        setIsOpen={toggleModal}
        productsDicionary={productsDicionary}
        complementsDicionary={complementsDicionary}
        estabId={estabId}
      />
    </>
  );
}
export default ModalEditFiscal;