import React, { useCallback, useMemo, useContext, useEffect, useState } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft } from '../../../../layout/SubHeader/SubHeader';
import Page from '../../../../layout/Page/Page';
import Breadcrumb from '../../../../components/bootstrap/Breadcrumb';
import { demoPages } from '../../../../menu';
import { DefaultContext } from '../../../../contexts/default';
import Card, { CardBody } from '../../../../components/bootstrap/Card';
import Button from '../../../../components/bootstrap/Button';
import TableCustom from '../../../../components/MyCustom/TableCustom';
import SelectEstablishment from '../../../../components/MyCustom/SelectEstablishment';
import SelectStore from '../../../../components/MyCustom/SelectStore';
import ModalRegisterStoreProducts from '../../../../components/modals/StoreProducts/ModalRegister'
import ProductDB from '../../../../database/wrappers/product';
import useDarkMode from '../../../../hooks/useDarkMode';
import StoreProductDB from '../../../../database/wrappers/storeProduct';
import Money from '../../../../helpers/utils/money/money';
import { orderBy } from 'firebase/firestore';
import logo from '../../../../assets/img/logo_uget_black.png'
import UserHistory from '../../../../database/wrappers/userHistory';

const StoreProducts = () => {
  const { darkModeStatus } = useDarkMode();

  const { establishment, store, onShowAlert, user } = useContext(DefaultContext)
  const [products, setProducts] = useState(null)
  const [storeProductsDicionary, setstoreProductsDicionary] = useState(null)
  const [productsRender, setProductsRender] = useState([])
  const [productSelected, setProductSelected] = useState(null);
  const [showModal, setshowModal] = useState(false);

  useEffect(() => {
    if (!establishment) return;
    const onSubscribe = new ProductDB(establishment.id).on(setProducts, orderBy('name', 'asc'));
    return onSubscribe
  }, [establishment])

  useEffect(() => {
    if (!establishment || !store) return;
    const onSubscribe = new StoreProductDB(establishment.id, store.id).on(datas => {
      const dicionary = {};
      datas.forEach(data => dicionary[data.id] = data)
      setstoreProductsDicionary(dicionary)
    });
    return onSubscribe;
  }, [establishment, store])

  useEffect(() => {
    if (products !== null && storeProductsDicionary !== null) {
      const productsRender = products.map(product => {
        const storeProd = storeProductsDicionary[product.id];
        return {
          ...product,
          ...storeProd,
          defaultPrice: product.price,
          active: !storeProd ? true : storeProd.active,
          storeProdExist: !!storeProd,
          ifoodPrice: storeProd?.ifoodPrice || storeProd?.price || product?.price,
          rappiPrice: storeProd?.rappiPrice || storeProd?.price || product?.price
        }
      })

      setProductsRender(productsRender);
    }
  }, [products, storeProductsDicionary])

  const handleOpenModalEdit = useCallback((item) => {
    setProductSelected(item)
    setshowModal(true)
  }, [])

  const handleChangeStatus = useCallback((id, item) => {
    const onSuccess = () => { };
    const onError = () => {
      onShowAlert({
        title: 'ERRO!',
        msm: 'Não foi possível atualizar o status do produto',
        icon: 'Error',
        color: 'danger'
      })
    };
    if (item.storeProdExist) {
      new StoreProductDB(establishment.id, store.id)
        .update(item.id, {
          active: !item.active
        })
        .then(() => {
          onSuccess()
          new UserHistory(establishment.id, user, "o Produto", store.name)
            .update(item.id, { data: { active: !item.active }, selected: item })
            .finally(() => { })
        })
        .catch(onError)
    } else {
      new StoreProductDB(establishment.id, store.id)
        .define(item.id, {
          active: !item.active
        })
        .then(() => {
          onSuccess()
          new UserHistory(establishment.id, user, "o Produto", store.name)
            .update(item.id, { data: { active: !item.active }, selected: item })
            .finally(() => { })
        })
        .catch(onError)
    }
  }, [establishment, store])

  const columns = useMemo(() => ([
    {
      label: 'Imagem',
      field: 'image_url',
      format: row => (
        <>
          {row.image_url ? (
            <img src={row.image_url} alt="Imagem" width={54} height={54} />
          ) : (
            <img src={logo} alt="Imagem" width={60} height='auto' />
          )}
        </>
      ),
      noExport: true
    },
    {
      label: 'Nome',
      field: 'name',
    },
    {
      label: 'Preço Padrão',
      field: 'defaultPrice',
      format: row => Money.centsToMaskMoney(row.defaultPrice),
      formatExport: value => Money.centsToCoin(value),
    },
    {
      label: 'Preço Na Loja',
      field: 'price',
      format: row => Money.centsToMaskMoney(row.price),
      formatExport: value => Money.centsToCoin(value),
    },
    {
      label: 'Preço No Ifood',
      field: 'ifoodPrice',
      format: row => Money.centsToMaskMoney(row?.ifoodPrice ?? 0),
      formatExport: value => Money.centsToCoin(value ?? 0),
    },
    {
      label: 'Preço Na Rappi',
      field: 'rappiPrice',
      format: row => Money.centsToMaskMoney(row?.rappiPrice ?? 0),
      formatExport: value => Money.centsToCoin(value ?? 0),
    },
    {
      label: 'Status',
      field: 'active',
      format: row => (
        <>
          <Button
            isLink
            color={row.active ? 'success' : 'danger'}
            icon='Circle'
            onClick={() => handleChangeStatus(row.id, row)}
            className='text-nowrap'>
            {row.active ? 'Ativo' : 'Inativo'}
          </Button>
        </>
      )
    },
    {
      label: 'Ações',
      field: 'actions',
      format: row => (
        <div className='w-50 d-flex justify-content-between'>
          <Button
            color={darkModeStatus ? 'light' : 'dark'}
            icon="edit"
            hoverShadow="sm"
            size="sm"
            text=''
            onClick={() => handleOpenModalEdit(row)}
          />
        </div>
      ),
      noExport: true
    },
  ]), [handleChangeStatus, handleOpenModalEdit])

  return (
    <>
      <PageWrapper title={demoPages.pdvLoja.subMenu.product.text}>
        <SubHeader>
          <SubHeaderLeft>
            <Breadcrumb
              list={[
                { title: 'PDV Loja', to: '/pdvLoja' },
                { title: 'Produtos na Loja', to: '/pdvLoja/products' },
              ]}
            />
          </SubHeaderLeft>
        </SubHeader>
        <Page container='fluid'>
          <Card>
            <CardBody className='table-responsive p-5'>
              <div className='row d-flex align-items-center mt-4 mx-1' >
                <SelectEstablishment />
                <SelectStore />
              </div>

              <TableCustom columns={columns} rows={productsRender} fileName={'Produtos-na-loja'} />
            </CardBody>
          </Card>
        </Page>
      </PageWrapper>

      <ModalRegisterStoreProducts
        open={showModal}
        setIsOpen={setshowModal}
        setIsClose={() => setshowModal(false)}
        productSelected={productSelected}
      />
    </>
  );
};

export default StoreProducts;
