import { QueryConstraint, Unsubscribe, getDocs, limit, query, where } from "firebase/firestore";
import FirestorePipe from "../utils/pipe";
import EstablishmentDB from "./establishment";
import StoreDB from "./store";
import ListVip from "../entities/listCourtesy.entity";


class TerminaisFiscalDB extends FirestorePipe {

  public static readonly colName = 'TerminaisFiscal'


  constructor(idEstab: string, idStore: string) {
    if (!idEstab || !idStore) throw new Error('Informe o id do estabelecimento');
    super(`${EstablishmentDB.colName}/${idEstab}/${StoreDB.colName}/${idStore}/${TerminaisFiscalDB.colName}`);
  }

  public writeBatch(datas: ListVip[]): Promise<void> {
    return this._writeBatch<ListVip>(datas)
  }
  public create(data: ListVip): Promise<any> {
    return this._create(data);
  }
  public update(id: string, data: ListVip): Promise<any> {
    return this._update(id, data);
  }
  public delete(id: string): Promise<any> {
    return this._delete(id);
  }
  public getAll(...params: QueryConstraint[]): Promise<ListVip[]> {
    return this._getAll<ListVip>(...params);
  }
  public get(id: string): Promise<ListVip> {
    return this._get(id);
  }
  public on(listener: (data: ListVip[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  }

}

export default TerminaisFiscalDB;