import React, { memo, useState, useEffect, useContext, useCallback, useMemo } from 'react';

import ModalEditFiscal from '../EditDeliveryFiscal/editFiscal';
import { ResultsContext } from '../../../contexts/results';
import { DefaultContext } from '../../../contexts/default';
import { useFormik } from 'formik';
import PreAlert from '../../../helpers/utils/preAlert';
import api from '../../../services/api';
import { getFiscalData } from '../../../services/apiFiscal';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import Spinner from '../../bootstrap/Spinner';
import Textarea from '../../bootstrap/forms/Textarea';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Money from '../../../helpers/utils/money/money';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import Button from '../../bootstrap/Button';


const ModalDeliveryFiscal = ({ open, setIsOpen, setIsClose, rowSelected }) => {
  const { dataFilter, } = useContext(ResultsContext)
  const { estabSelected, onShowAlert } = useContext(DefaultContext)
  const [loading, setloading] = useState(false);
  const [modal, setModal] = useState(false);
  const [error, setError] = useState('');
  const [openEdit, setOpenEdit] = useState(false)
  const idSales = rowSelected?.id;
  const toggleModal = useCallback(() => setOpenEdit(state => !state), [])
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const { store } = dataFilter;
      if (!estabSelected || !store || store === '#') {
        setSubmitting(false);
        return onShowAlert(PreAlert.error('É preciso que uma loja esteja selecionada no filtro.'));
      }
      setloading(true);
      try {
        await api.post('routes/sendEmail', {
          user_name: 'Cliente',
          store_id: store,
          estab_id: estabSelected,
          file_url: rowSelected?.fiscal?.file_url || '',
          email_to: [values.email],
        });
        setModal(true);
        setIsClose();
        resetForm();
      } catch (error) {
        console.error('Erro ao enviar o e-mail:', error);
        onShowAlert(PreAlert.error('Erro ao enviar o e-mail. Por favor, tente novamente.'));
      } finally {
        setloading(false);
      }
      setSubmitting(false);
    },
  });
  const fetchError = useCallback(async () => {
    const response = await getFiscalData(rowSelected?.fiscal?.id)
    setError(response?.data?.log);
  }, [rowSelected])

  useEffect(() => {
    if (open) fetchError();
    if (!open) setError(null);
  }, [fetchError, open])


  return (
    <>
      <Modal
        id={'modal-request'}
        titleId={'request'}
        isOpen={open}
        setIsOpen={setIsOpen}
        isStaticBackdrop={true}
        isScrollable={false}
        isCentered={true}
        size="lg" // 'sm' || 'lg' || 'xl' 
        isAnimation={true}
      >
        <ModalHeader setIsOpen={setIsOpen}>
          <ModalTitle id="register-request">Dados Fiscais - {rowSelected?.clientName || ''}</ModalTitle>
        </ModalHeader>
        <ModalBody>
          {loading ?
            <div className='d-flex justify-content-center flex-column  align-items-center  '>
              <Spinner size="4rem" color='success' />
              <p className='pt-3'>Enviando e-mail..</p>
            </div>

            :
            <>
              {error && (
                <Textarea
                  value={error}
                  disabled
                  rows={5}
                />
              )}

              <form onSubmit={formik.handleSubmit} className='p-1'>
                {/* Inputs */}
                <div className="row g-4">
                  {/* Nome */}
                  <FormGroup id="name" label="Nome Cliente" className='col-md-8'>

                    <Input
                      disabled
                      value={rowSelected?.clientName || ''}
                    />
                  </FormGroup>
                  {/* VALOR */}
                  <FormGroup id="subTotal" label="Valor" className='col-md-4'>
                    <Input
                      disabled
                      value={Money.centsToMaskMoney(rowSelected?.subTotal) || ''}
                    />
                  </FormGroup>
                  {/* CHECKS */}
                  <FormGroup id="issued" label="Já emitida?" className='col-md-2'>
                    <ChecksGroup>
                      {/* check estatico com valor padra de rowSelected?.fiscal?.issued  */}
                      <Checks
                        type='switch'
                        id='issued'
                        name='issued'
                        value={rowSelected?.fiscal?.issued}
                        onChange={formik.handleChange}
                        checked={rowSelected?.fiscal?.issued}
                        isInline
                      />
                    </ChecksGroup>
                  </FormGroup>

                  <FormGroup id="download" label="Nota Fiscal" className='col-md-2'>
                    <div>
                      <Button
                        color="success"
                        icon="Download"
                        rounded={1}
                        onClick={() => {
                          const fileUrl = rowSelected?.fiscal?.file_url;
                          if (fileUrl) {
                            window.open(fileUrl, '_blank');
                          }
                        }}
                        shadow="sm"
                        hoverShadow="sm"
                        size="sm"
                        disabled={!rowSelected?.fiscal?.issued}
                      >
                        Download
                      </Button>
                    </div>
                  </FormGroup>
                  <FormGroup id="email" label="Email" className='col-md-6'>
                    <div className='d-flex flex-row gap-2'>
                      <Input
                        id="email"
                        disabled={!rowSelected?.fiscal?.issued}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        isValid={formik.isValid}
                        isTouched={formik.touched.email}
                        invalidFeedback={formik.errors.email}
                        validFeedback='Assim está bom!'
                        placeholder='Digite o email para envio'
                        name="email"
                      />
                      <Button
                        color="success"
                        icon="Send"
                        rounded={1}
                        onClick={formik.handleSubmit}
                        shadow="sm"
                        hoverShadow="sm"
                        size="sm"
                        disabled={!rowSelected?.fiscal?.issued}
                      />
                    </div>
                  </FormGroup>
                  <div className="d-flex flex-row-reverse mt-5 gap-3" style={{ marginTop: 10 }}>
                    <Button
                      color="danger"
                      icon="cancel"
                      rounded={1}
                      onClick={setIsClose}
                      shadow="sm"
                      hoverShadow="sm"
                      size="sm"
                    >
                      Fechar
                    </Button>
                    {!rowSelected?.fiscal?.issued &&

                      <Button
                        color="warning"
                        icon="edit"
                        rounded={1}
                        onClick={toggleModal}
                        shadow="sm"
                        hoverShadow="sm"
                        size="sm"
                      >
                        Corrigir Fiscal

                      </Button>
                    
                    }

                  </div>
                  {/* Buttons */}
                </div>
              </form>
            </>
          }
        </ModalBody>
      </Modal>
      <Modal
        id={'modal-request'}
        titleId={'request'}
        isOpen={modal}
        setIsOpen={() => setModal(false)}
        isStaticBackdrop={true}
        isScrollable={false}
        isCentered={true}
        size="sm" // 'sm' || 'lg' || 'xl' 
        isAnimation={true}>
        <ModalHeader >
          <ModalTitle id="register-request">Confirmação de envio.</ModalTitle>
        </ModalHeader>
        <ModalBody>
          Nota fiscal enviada para o e-mail do destinatário.
          <div className="d-flex flex-row-reverse mt-5" style={{ marginTop: 10 }}>
            <Button
              color="danger"
              icon="cancel"
              rounded={1}
              onClick={() => setModal(false)}
              shadow="sm"
              hoverShadow="sm"
              size="sm"
            >
              Fechar
            </Button>
          </div>
        </ModalBody>
      </Modal>

      <ModalEditFiscal
        id={idSales}
        bodyData={rowSelected}
        open={openEdit}
        setIsClose={toggleModal}
        setIsOpen={toggleModal}
        onClose={setIsClose}
      />
    </>
  );
};

export default memo(ModalDeliveryFiscal);
