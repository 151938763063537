import { limit, QueryConstraint, Unsubscribe, where } from "firebase/firestore";
import randomString from "../../helpers/utils/functions/randomString";
import FireTotenPipe from "../utils/pipe";
import EstablishmentDB from "./establishment";
import TotenNotes from "../entities/totemNotes.entity";

class TotenNotesDB extends FireTotenPipe {

  public static readonly colName = 'TotensNotes'

  constructor(idEstab: string) {
    if (!idEstab)
      throw new Error('Informe o id do estabelecimento')
    super(`${EstablishmentDB.colName}/${idEstab}/${TotenNotesDB.colName}`);
  }

  public async create(data: TotenNotes): Promise<any> {
    const key = await this.generateKey();
    return this._create({
      ...data,
      key
    });
  }
  public update(id: string, data: TotenNotes): Promise<any> {
    return this._update(id, data);
  }
  public delete(id: string): Promise<any> {
    return this._delete(id);
  }
  public getAll(...params: QueryConstraint[]): Promise<TotenNotes[]> {
    return this._getAll<TotenNotes>(...params);
  }
  public get(id: string): Promise<TotenNotes> {
    return this._get(id);
  }
  public on(listener: (data: TotenNotes[]) => void, ...params: QueryConstraint[]): Unsubscribe {
    return this._on(listener, ...params);
  }

  private async generateKey() {
    let key;
    let totens;
    do {
      key = randomString(8);
      totens = await this.getAll(
        where('key', '==', key),
        limit(1)
      )
    } while (totens.length !== 0);
    return key;
  }

}

export default TotenNotesDB;